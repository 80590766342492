import React from "react"
import { graphql, StaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Flickity from 'react-flickity-component'

const clientOptions = {
  prevNextButtons: true,
  pageDots: false,
  cellSelector: '.slider_cell',
  wrapAround: true,
  setGallerySize: true,
  cellAlign: 'center',
  autoPlay: 3000,
  groupCells: 1
}

export default function Clients() {
  return (
    <StaticQuery
      query={graphql`
        query {
          prismicClients {
            data {
              clients {
                logo {
                  alt
                  gatsbyImageData
                }
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <div className="w-full text-base font-sans pt-24">
            <div className="w-9/12 mx-auto max-w-screen-2xl prose text-center">
              <h3>Brands protected, renewed or restored by McCabes</h3>
            </div>
          </div>
          <div className="w-full text-base font-sans pt-6 pb-24">
            <div className="w-9/12 sm:w-10/12 mx-auto max-w-screen-2xl">
              <Flickity
                className={'slider'}
                elementType={'div'}
                options={clientOptions}
                disableImagesLoaded={true}
                reloadOnUpdate={false}
                static={true}
              >
                {data.prismicClients.data.clients.map((entry, i) => (
                  <div key={`logo_${i}`} className="slider_cell flex">
                    <div className="w-full flex justify-center items-center">
                      <div>
                        <GatsbyImage className="grayscale" style={{ width: "100%", height: "100%", display: "block", objectFit: 'contain' }} image={entry.logo.gatsbyImageData} alt={entry.logo.alt ? entry.logo.alt : ''} />
                      </div>
                    </div>
                  </div>
                ))}
              </Flickity>
            </div>
          </div>
        </>
      )}
      
    />
  )
}